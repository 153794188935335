<template>
  <div class="container-fluid cart">
    <div class="row subbar">
      <div class="container-fluid">
        <div class="side-push">
          <div class="row">
            <div class="col">
              <nav>
                <span
                  ><a href="/">{{ $t("menu.home") }}</a></span
                >
                <span
                  >/<a href="/">{{ $t("menu.cart") }}</a></span
                >
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      size="lg"
      :hide-footer="true"
      centered
      ref="changeCart"
      id="changeCart"
      :title="$t('cart.change-basket')"
    >
      <div v-if="activeCart.status === null">
        <div class="text-center pt-3 pb-3">
          <h5 class="text-muted">{{ $t("cart.create-basket-first") }}</h5>
        </div>
      </div>
      <form ref="form" class="pl-3 pr-3 pt-2" v-if="activeCart.status !== null">
        <div class="row">
          <div
            class="col-6"
            v-for="singleCart in activeCarts"
            :key="singleCart.id"
            :value="singleCart"
          >
            <button
              type="button"
              class="mb-3 btn btn-light w-100"
              @click="setNewActiveCart(singleCart)"
            >
              {{ singleCart.projekt }}
            </button>
          </div>
        </div>
      </form>
    </b-modal>
    <div class="side-push">
      <div class="row title-wrapper">
        <div class="col-6">
          <template v-if="editTitle">
            <input type="text" v-model="activeCart.projekt" />
            <button class="btn btn-sm btn-primary" @click="updateCart">
              {{ $t("menu.cart") }}
            </button>
          </template>
          <template v-if="!editTitle">
            <h1 class="mb-0 mt-0">
              {{ $t("menu.cart") }}: {{ activeCart.projekt }}
            </h1>
          </template>
        </div>
        <div class="col-6 text-right">
          <select
            v-model="currentCart"
            @change="setNewActiveCart($event)"
            v-if="1 === 2"
          >
            <option
              v-for="singleCart in activeCarts"
              :key="singleCart.id"
              :value="singleCart"
            >
              {{ singleCart.projekt }} ({{ singleCart.lines.length }} produkter)
            </option>
          </select>

          <b-button
            v-b-modal.changeCart
            variant="btn btn-outline-dark mr-3 ml-2k"
            v-if="activeCarts.length !== 0"
          >
            {{ $t("cart.change-basket") }}
          </b-button>
          <button
            class="btn btn-outline-dark mr-5"
            @click="changeStatus('AN')"
            v-if="canCancel"
          >
            {{ $t("cart.cancel-basket") }}
          </button>
          <b-button v-b-modal.newCart variant="btn btn-sm btn-dark"
            >{{ $t("cart.new-basket") }}
          </b-button>

          <b-modal
            :ok-title="$t('misc.save')"
            ok-only
            centered
            @ok="createCart"
            ref="newCart"
            id="newCart"
            :title="$t('cart.new-basket')"
          >
            <div class="pl-3 pr-3 pt-2 pb-2">
              <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group>
                  <b-form-input
                    :placeholder="$t('cart.new-basket-name')"
                    id="name-input"
                    v-model="cart.name"
                    required
                  ></b-form-input>
                </b-form-group>
              </form>
            </div>
          </b-modal>
        </div>
      </div>
    </div>

    <div class="side-push" v-if="activeCart.status === null">
      <div class="text-center pt-5">
        <h5 class="text-muted">{{ $t("cart.create-first-cart") }}</h5>
      </div>
    </div>
    <div class="side-push" v-if="activeCart.status !== null">
      <div class="row">
        <div class="col-12">
          <div class="alert alert-warning" role="alert" v-if="getPunchout">
            {{ $t("cart.punchout") }}
          </div>
        </div>
        <div class="col-12">
          <table class="cart-table" cellspacing="0">
            <thead>
              <tr>
                <th class="product-name" width="34%">
                  {{ $t("cart.Produkt") }}
                </th>
                <th class="product-article-number">
                  {{ $t("cart.articlenumber") }}
                </th>
                <th class="product-quantity">{{ $t("cart.quantity") }}</th>
                <th class="product-price">{{ $t("cart.unit-price") }}</th>
                <th colspan="2" class="product-subtotal">
                  {{ $t("cart.sub-price") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="activeCart.lines.length === 0">
                <tr>
                  <td colspan="6" class="text-center pt-5">
                    <h5 class="text-muted">{{ $t("cart.basket-empty") }}</h5>
                  </td>
                </tr>
              </template>
              <template v-if="activeCart.lines.length !== 0">
                <tr
                  class="woocommerce-cart-form__cart-item cart_item"
                  v-for="cartLine in activeCart.lines.filter(
                    item => item.prisl_underrad === '0'
                  )"
                  :key="cartLine.id"
                >
                  <td>
                    <router-link
                      tag="div"
                      :to="{
                        name: 'product.view',
                        params: {
                          shop: currentShop.toString(),
                          uid: getUid(currentShop, cartLine)
                        }
                      }"
                    >
                      {{ cartLine.benamn1 }}
                    </router-link>

                    <div
                      class="text-muted pt-2"
                      :key="subline.prisl_radnr + '' + subline.prisl_underrad"
                      v-for="subline in getCartLineSubLines(cartLine.radnr)"
                    >
                      {{ subline.antal }} x {{ subline.benamn1 }} á
                      {{ formatPrice(subline.pris, cartLine.valuta) }}
                    </div>
                  </td>
                  <td>{{ cartLine.artikelnr }}</td>
                  <td>
                    <div class="cart-multiple">
                      {{ cartLine.antal }}
                    </div>
                  </td>
                  <td>
                    {{
                      formatPrice(
                        getTotalLinePrice(cartLine, false, true),
                        cartLine.valuta
                      )
                    }}
                  </td>
                  <td>
                    {{
                      formatPrice(
                        getTotalLinePrice(cartLine, true, true),
                        cartLine.valuta
                      )
                    }}
                  </td>
                  <td width="50" class="text-right">
                    <button
                      v-if="cartLine.prisl_underrad === '0'"
                      @click="removeLineFromCart(cartLine)"
                      class="btn btn-dark btn-remove-cart"
                    >
                      <img
                        src="https://cdn2.iconfinder.com/data/icons/ios-7-icons/50/plus-512.png"
                      />
                    </button>
                  </td>
                </tr>
              </template>
            </tbody>
            <thead v-if="activeCart.lines.length !== 0">
              <tr>
                <th
                  style="border-top: 1px solid #e9e9e9"
                  class="product-name"
                  colspan="3"
                ></th>
                <th
                  style="border-top: 1px solid #e9e9e9"
                  class="product-name"
                  colspan="3"
                >
                  <div
                    style="
                      background-color: #f5f5f5;
                      margin-top: 15px;
                      padding: 5px 20px;
                    "
                  >
                    <table
                      class="cart-table cart-table-summary"
                      cellspacing="0"
                    >
                      <tr>
                        <th class="product-quantity strong">
                          {{ $t("cart.subtotal") }}
                        </th>
                        <th class="product-subtotal text-right strong">
                          {{ getSum(activeCart, 1, false, true) }}
                        </th>
                      </tr>
                      <tr>
                        <th
                          style="border-top: 1px solid #e9e9e9"
                          class="product-quantity text-muted"
                        >
                          {{ $t("cart.vat") }} {{ getVAT() }}%
                        </th>
                        <th
                          style="border-top: 1px solid #e9e9e9"
                          class="product-subtotal text-right text-muted"
                        >
                          {{
                            getSum(activeCart, getVAT() / 100 + 1, true, true)
                          }}
                        </th>
                      </tr>
                      <tr>
                        <th
                          style="border-top: 1px solid #e9e9e9"
                          class="product-quantity strong"
                        >
                          {{ $t("cart.total") }}
                        </th>
                        <th
                          style="border-top: 1px solid #e9e9e9"
                          class="product-subtotal text-right strong"
                        >
                          {{
                            getSum(activeCart, getVAT() / 100 + 1, false, true)
                          }}
                        </th>
                      </tr>
                    </table>
                  </div>
                </th>
              </tr>
            </thead>
          </table>

          <div class="text-right pt-3" v-if="activeCart.lines.length !== 0">
            <div v-if="getPunchout">
              <button
                v-if="getProtocol() === 'csxml'"
                @click="sendCXMLPunchout"
                class="btn btn-primary ml-2"
                style=" width: 30%; font-size: 17px;
              height: inherit !important; line-height: inherit !important; "
              >
                {{ $t("cart.order-basket") }}
              </button>

              <form id="form" :action="getHookURL()" method="post">
                <button
                  class="btn btn-primary"
                  style="
                    width: 30%;
                    font-size: 17px;
                    height: inherit !important;
                    line-height: inherit !important;
                  "
                  type="submit"
                  id="button"
                >
                  {{ $t("cart.order-basket") }}
                </button>

                <table id="table">
                  <tbody>
                    <tr>
                      <td
                        v-for="(cartLine, index) in activeCart.lines"
                        :key="cartLine.id"
                      >
                        <input
                          type="hidden"
                          :name="
                            'NEW_ITEM-VENDORMAT[' + (parseInt(index) + 1) + ']'
                          "
                          :value="cartLine.artikelnr"
                        />
                        <input
                          type="hidden"
                          :name="
                            'NEW_ITEM-DESCRIPTION[' +
                              (parseInt(index) + 1) +
                              ']'
                          "
                          :value="cartLine.benamn1"
                        />
                        <input
                          type="hidden"
                          :name="
                            'NEW_ITEM-PRICE[' + (parseInt(index) + 1) + ']'
                          "
                          :value="
                            getLinePriceWithDiscount(cartLine, false, true)
                          "
                        />
                        <input
                          type="hidden"
                          :name="
                            'NEW_ITEM-PRICEUNIT[' + (parseInt(index) + 1) + ']'
                          "
                          value="1"
                        />
                        <input
                          type="hidden"
                          :name="
                            'NEW_ITEM-LEADTIME[' + (parseInt(index) + 1) + ']'
                          "
                          value="14"
                        />
                        <input
                          type="hidden"
                          :name="
                            'NEW_ITEM-CURRENCY[' + (parseInt(index) + 1) + ']'
                          "
                          :value="cartLine.valuta"
                        />
                        <input
                          type="hidden"
                          :name="
                            'NEW_ITEM-MATGROUP[' + (parseInt(index) + 1) + ']'
                          "
                          :value="cartLine.unspsc"
                        />
                        <input
                          type="hidden"
                          :name="
                            'NEW_ITEM-EXT_CATEGORY[' +
                              (parseInt(index) + 1) +
                              ']'
                          "
                          :value="cartLine.unspsc"
                        />
                        <input
                          type="hidden"
                          :name="'NEW_ITEM-UNIT[' + (parseInt(index) + 1) + ']'"
                          value="EA"
                        />
                        <input
                          type="hidden"
                          :name="
                            'NEW_ITEM-QUANTITY[' + (parseInt(index) + 1) + ']'
                          "
                          :value="cartLine.antal"
                        />
                        <input
                          type="hidden"
                          :name="
                            'NEW_ITEM-EXT_CATEGORY[' +
                              (parseInt(index) + 1) +
                              ']'
                          "
                          value="1411000"
                        />
                        <input
                          type="hidden"
                          :name="
                            'NEW_ITEM-CUST_FIELD2[' +
                              (parseInt(index) + 1) +
                              ']'
                          "
                          value="25"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="row mb-4 mt-5"
      style="background: #f5f5f5"
      v-if="activeCart.status !== null && !getPunchout"
    >
      <div class="col-12 pt-5 pb-5">
        <div class="side-push">
          <div class="row">
            <div class="col-6"></div>
            <div class="col-6">
              <div class="row mb-3">
                <div class="col-4 pt-1">
                  <h6>{{ $t("cart.customer") }}</h6>
                </div>
                <div class="col-8">
                  <v-select
                    class="v-select-light"
                    @input="updateCartInfo"
                    transition="none"
                    label="option_label"
                    :clearable="false"
                    :searchable="false"
                    :options="customers"
                    v-model="activeCart.customer"
                  ></v-select>
                </div>
              </div>

              <div class="row mb-5">
                <div class="col-4 pt-1">
                  <h6>
                    {{ $t("cart.customer-reference") }}
                    <small
                      class="text-danger"
                      v-if="
                        $store.getters.getCurrentShop.info.kundref_obl === 'J'
                      "
                      >*</small
                    >
                  </h6>
                </div>
                <div class="col-8">
                  <input
                    type="text"
                    :placeholder="
                      $store.getters.getCurrentShop.info.kundref_info
                    "
                    class="form-cart"
                    v-model="activeCart.reference"
                  />
                </div>
              </div>

              <div class="row mb-5">
                <div class="col-4 pt-1">
                  <h6>
                    {{ $t("cart.customer-information") }}
                    <small
                      class="text-danger"
                      v-if="$store.getters.getCurrentShop.info.kont_obl === 'J'"
                      >*</small
                    >
                  </h6>
                </div>
                <div class="col-8">
                  <input
                    type="text"
                    maxlength="50"
                    :placeholder="
                      $t('cart.customer-firstname') +
                        ' ' +
                        $t('cart.customer-lastname')
                    "
                    class="form-cart mb-2"
                    v-model="activeCart.contact_name"
                  />
                  <input
                    type="email"
                    maxlength="100"
                    placeholder="E-post"
                    class="form-cart mb-2"
                    v-model="activeCart.contact_email"
                  />
                  <input
                    type="text"
                    :placeholder="$t('cart.order-reference')"
                    class="form-cart"
                    v-model="activeCart.customer_order_number"
                  />
                </div>
              </div>

              <div class="row mb-5">
                <div class="col-4 pt-1">
                  <h6>
                    {{ $t("cart.customer-delivery-address") }}
                    <small
                      class="text-danger"
                      v-if="
                        $store.getters.getCurrentShop.info.levadr_obl === 'J'
                      "
                      >*</small
                    >
                  </h6>
                </div>
                <div class="col-8">
                  <input
                    type="text"
                    :placeholder="$t('cart.customer-address')"
                    maxlength="40"
                    class="form-cart mb-2"
                    v-model="activeCart.delivery_address"
                  />
                  <input
                    type="email"
                    placeholder="Postnummer"
                    maxlength="40"
                    class="form-cart mb-2"
                    v-model="activeCart.delivery_postal_code"
                  />
                  <input
                    type="text"
                    :placeholder="$t('cart.customer-city')"
                    maxlength="40"
                    class="form-cart"
                    v-model="activeCart.delivery_city"
                  />
                </div>
              </div>

              <div class="row mb-5">
                <div class="col-4 pt-1">
                  <h6>
                    {{ $t("cart.customer-delivery-contact") }}
                    <small
                      class="text-danger"
                      v-if="
                        $store.getters.getCurrentShop.info.levkontakt_obl ===
                          'J'
                      "
                      >*</small
                    >
                  </h6>
                </div>
                <div class="col-8">
                  <input
                    type="text"
                    :placeholder="
                      $t('cart.customer-firstname') +
                        ' ' +
                        $t('cart.customer-lastname')
                    "
                    class="form-cart mb-2"
                    maxlength="50"
                    v-model="activeCart.delivery_contact_name"
                  />
                  <input
                    type="email"
                    placeholder="E-post"
                    maxlength="100"
                    class="form-cart mb-2"
                    v-model="activeCart.delivery_contact_email"
                  />
                  <input
                    type="text"
                    maxlength="30"
                    placeholder="Telefon"
                    class="form-cart"
                    v-model="activeCart.delivery_contact_phone"
                  />
                </div>
              </div>

              <div class="row mb-5">
                <div class="col-4 pt-1">
                  <h6>
                    {{ $t("cart.information") }}
                    <small
                      class="text-danger"
                      v-if="
                        $store.getters.getCurrentShop.info.extratext_obl === 'J'
                      "
                      >*</small
                    >
                  </h6>
                </div>
                <div class="col-8">
                  <textarea
                    :placeholder="
                      $store.getters.getCurrentShop.info.extratext_info
                    "
                    class="form-cart"
                    rows="6"
                    v-model="activeCart.other_information"
                  ></textarea>
                </div>
              </div>

              <div class="row mb-5">
                <div class="col-4 pt-1"></div>
                <div class="col-8 text-right">
                  <button
                    class="btn btn-black"
                    @click="changeStatus('GK')"
                    v-if="canOrder && !getPunchout"
                  >
                    {{ $t("cart.order-basket") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputAPI from "@/services/api/input";

export default {
  props: {},
  methods: {
    sendCXMLPunchout() {
      const cartId = this.$store.getters.getActiveCart.ordernr;
      const shopId = this.$store.getters.getCurrentShop.shop_id;

      InputAPI.sendCXMLPunchout(cartId, this.getPunchout.instance)
        .then(response => {
          if (response.data && response.data.cxml && response.data.return_url) {
            this.submitCXMLForm(response.data.cxml, response.data.return_url);
          } else {
            this.$toast.error(this.$t("cart.cxml-punchout-error"), {
              timeout: 3000
            });
          }
        })
        .catch(error => {
          this.$toast.error(this.$t("cart.cxml-punchout-error"), {
            timeout: 3000
          });
          console.error("CXML Punchout error:", error);
        });
    },

    submitCXMLForm(cxml, returnUrl) {
      // Create a form element
      const form = document.createElement("form");
      form.method = "POST";
      form.action = returnUrl;

      // Create an input element for the cXML content
      const input = document.createElement("input");
      input.type = "hidden";
      input.name = "cxml-urlencoded";
      input.value = cxml;

      // Append the input to the form
      form.appendChild(input);

      // Append the form to the body
      document.body.appendChild(form);

      // Submit the form
      form.submit();

      // Remove the form from the body
      document.body.removeChild(form);
    },

    getUid(shop, cartLine) {
      return shop + "-" + cartLine.listnr + "-" + cartLine.prisl_radnr;
    },

    getVAT() {
      return this.$store.getters.getCurrentShop.info.aktuell_moms;
    },

    getProtocol() {
      return this.getPunchout.protocol;
    },
    getHookURL() {
      let url = this.getPunchout.HOOK_URL;
      if (Array.isArray(url)) {
        return url.find(item => item.length !== 0);
      }
      return url;
    },
    isEmpty(value) {
      typeof value;
      return value === null || value === "";
    },

    updateCartInfo() {
      let cart_id = this.$store.getters.getActiveCart.ordernr;

      this.edit.customer = false;
      this.edit.order_number = false;
      this.edit.reference = false;
      this.edit.other_information = false;
      InputAPI.updateCartInfo(cart_id, this.activeCart).then(response => {
        this.$store.dispatch("setActiveCart", response.data.data);
        this.$toast.success("Sparad", {
          timeout: 3000
        });
      });
    },

    async safeUpdateCartLine(
      antal,
      radnr,
      prisl_radnr,
      listnr,
      prisl_underrad
    ) {
      try {
        return await this.updateCartLine(
          antal,
          radnr,
          prisl_radnr,
          listnr,
          prisl_underrad
        );
      } catch (error) {
        this.$toast.error(this.$t("cart.unable-update-product"), {
          timeout: 3000
        });
      }
    },

    updateLineQty(line, qty) {
      let oldQty = parseInt(line.antal);
      line.antal = parseInt(line.antal) + qty;
      let multiply = line.antal / oldQty;

      this.getCartLineSubLines(line.radnr).forEach(part => {
        part.antal = Math.round(parseInt(part.antal) * multiply);
      });

      clearTimeout(this.debounceTimer);
      let response;
      this.debounceTimer = setTimeout(async () => {
        response = await this.safeUpdateCartLine(
          line.antal,
          line.radnr,
          line.prisl_radnr,
          line.listnr,
          line.prisl_underrad
        );

        for (const part of this.getCartLineSubLines(line.radnr)) {
          response = await this.safeUpdateCartLine(
            part.antal,
            part.radnr,
            part.prisl_radnr,
            part.listnr,
            part.prisl_underrad
          );
        }

        this.$store.dispatch("setActiveCart", response.data.data);
      }, 800);
    },

    getLinePriceWithDiscount(line, total, includeDiscount) {
      let price = 0;
      if (line.rabatt !== null && line.rabatt !== "0") {
        if (includeDiscount) {
          price = line.pris - line.pris * (line.rabatt / 100);
        } else {
          price = line.pris;
        }
      } else {
        price = line.pris;
      }
      price = parseFloat(price);

      return price;
    },
    getTotalLinePrice(line, total, includeDiscount) {
      let price = 0;
      let priceParts = 0;
      if (line.rabatt !== null && line.rabatt !== "0") {
        if (includeDiscount) {
          price = line.pris - line.pris * (line.rabatt / 100);
        } else {
          price = line.pris;
        }
      } else {
        price = line.pris;
      }
      price = parseFloat(price);

      this.getCartLineSubLines(line.radnr).forEach(part => {
        priceParts = priceParts + parseInt(part.pris) * part.antal;
      });

      if (total) {
        price = price * line.antal + priceParts;
      } else {
        price = price + priceParts / line.antal;
      }

      return price;
    },
    getCartLineSubLines(line_no) {
      return this.activeCart.lines.filter(
        line => line.radnr === line_no && line.prisl_underrad !== "0"
      );
    },
    changeStatus(status) {
      if (status === "GK") {
        if (
          this.$store.getters.getCurrentShop.info.kundref_obl === "J" &&
          !this.activeCart.reference
        ) {
          this.$toast.error("Kundreferens är obligatorisk.", {
            timeout: 3000
          });
          return;
        }

        if (
          this.$store.getters.getCurrentShop.info.bestnr_obl === "J" &&
          !this.activeCart.customer_order_number
        ) {
          this.$toast.error("Beställningsnummer är obligatorisk.", {
            timeout: 3000
          });
          return;
        }

        if (
          this.$store.getters.getCurrentShop.info.extratext_obl === "J" &&
          !this.activeCart.other_information
        ) {
          this.$toast.error("Övrig information är obligatorisk.", {
            timeout: 3000
          });
          return;
        }

        if (
          this.$store.getters.getCurrentShop.info.levkontakt_obl === "J" &&
          !this.activeCart.delivery_contact_name &&
          !this.activeCart.delivery_contact_email &&
          !this.activeCart.delivery_contact_phone
        ) {
          this.$toast.error("Leveranskontakt är obligatorisk.", {
            timeout: 3000
          });
          return;
        }

        if (
          this.$store.getters.getCurrentShop.info.levadr_obl === "J" &&
          !this.activeCart.delivery_address &&
          !this.activeCart.delivery_postal_code &&
          !this.activeCart.delivery_city
        ) {
          this.$toast.error("Leveransadress är obligatorisk.", {
            timeout: 3000
          });
          return;
        }

        if (
          this.$store.getters.getCurrentShop.info.kont_obl === "J" &&
          !this.activeCart.contact_name &&
          !this.activeCart.contact_email
        ) {
          this.$toast.error("Kontaktuppgift till beställare är obligatorisk", {
            timeout: 3000
          });
          return;
        }
      }

      let cart_id = this.$store.getters.getActiveCart.ordernr;
      let shop_id = this.$store.getters.getActiveCart.shop_id;
      InputAPI.updateCartInfo(cart_id, this.activeCart).then(response => {
        InputAPI.setCartStatus(cart_id, shop_id, status).then(response => {
          this.$store.dispatch("setActiveCart", response.data.data);
          this.$store.dispatch("getCarts");
          if (status === "AN") {
            this.$toast.success(
              this.$t("cart.basket-cancelled") +
                " (" +
                this.$store.getters.getActiveCart.projekt +
                ")",
              {
                timeout: 3000
              }
            );
            this.selectNextAvailableCart();
          }

          if (status === "GK") {
            this.$store.dispatch("switchActiveShop", shop_id);
            this.$toast.success(
              this.$t("cart.basket-ordered") +
                " (" +
                this.$store.getters.getActiveCart.projekt +
                ")",
              {
                timeout: 3000
              }
            );
          }
          this.$store.dispatch("getCarts");
        });
      });
    },

    selectNextAvailableCart() {
      if (this.activeCarts.length > 0) {
        let nextCart = this.activeCarts[0];
        this.setNewActiveCart(nextCart);
      } else {
        this.$store.dispatch("setActiveCart", {
          lines: [],
          status: null,
          customer: {}
        });
      }
    },

    updateCart() {
      this.activeCart.lines.forEach(line => {
        this.updateCartLine(
          line.antal,
          line.radnr,
          line.prisl_radnr,
          line.listnr,
          line.prisl_underrad
        );
      });
    },

    removeLineFromCart(line) {
      this.updateCartLine(
        0,
        line.radnr,
        line.prisl_radnr,
        line.listnr,
        line.prisl_underrad
      )
        .then(response => {
          this.$store.dispatch("setActiveCart", response.data.data);
          this.$toast.success(
            "Produktet är borttagit (" +
              this.$store.getters.getActiveCart.projekt +
              ")",
            {
              timeout: 3000
            }
          );
        })
        .catch(error => {
          this.$toast.error(this.$t("cart.unable-remove-product"), {
            timeout: 3000
          });
        });
    },
    updateCartLine(qty, cart_line_no, line_no, list_no, subline_no) {
      let cart_id = this.$store.getters.getActiveCart.ordernr;

      return InputAPI.addLineToCart(
        cart_id,
        this.$store.getters.getCurrentShop.shop_id,
        {
          qty: qty,
          cart_line_no: cart_line_no,
          line_no: line_no,
          list_no: list_no,
          subline_no: subline_no
        }
      );
    },

    createCart() {
      this.$store
        .dispatch("createCart", {
          name: this.cart.name,
          shop_id: this.$store.getters.getCurrentShop.shop_id
        })
        .then(() => {
          this.$store.dispatch("getCarts");
          this.$refs["newCart"].hide();
          this.$toast.success(this.$t("cart.basket-created"), {
            timeout: 3000
          });
          this.cart.name = "";
        });
    },
    setNewActiveCart(cart) {
      this.$refs["changeCart"].hide();
      this.$store.dispatch("setActiveCart", cart);
    },
    getQtyOrder(order) {
      return order.lines.reduce(
        (total, arg) => parseFloat(total) + parseFloat(arg.antal),
        0
      );
    },
    getSum(order, multiply, vat, discount, formatted = true) {
      let lines = this.activeCart.lines.filter(
        item => item.prisl_underrad === "0"
      );
      let sum = 0;

      lines.forEach(line => {
        sum += this.getTotalLinePrice(line, true, discount);
      });

      if (vat) {
        sum = sum * multiply - sum;
      } else {
        sum = sum * multiply;
      }

      if (formatted) {
        return this.formatPrice(sum, this.activeCart.currency);
      } else {
        return sum;
      }
    },

    formatPrice(price, currency) {
      return this.$formatPrice(
        price,
        this.$store.getters.getCurrentShop.info.valuta
      );
    }
  },
  computed: {
    customers() {
      return this.$store.getters.getCustomers;
    },
    canCancel() {
      return this.activeCart.status === "EA" || this.activeCart.status === "OK";
    },

    canOrder() {
      return (
        (this.activeCart.status === "EA" || this.activeCart.status === "OK") &&
        this.getPermission >= 7
      );
    },

    getCustomers() {
      return this.$store.getters.getCustomers;
    },

    getPunchout() {
      return this.$store.getters.getPunchout;
    },
    getPermission() {
      return this.$store.getters.getPermission;
    },

    currentShop() {
      return this.$store.getters.getCurrentShop.shop_id;
    },
    activeCart() {
      return this.$store.getters.getActiveCart;
    },
    activeCarts() {
      return this.$store.getters.getActiveCarts;
    }
  },
  mounted() {
    this.$store.dispatch("getCarts");
  },
  data() {
    return {
      debounceTimer: false,
      currentCart: {},
      editTitle: false,
      showNewCartForm: false,
      edit: {
        other_information: false,
        customer: false,
        reference: false,
        order_number: false
      },
      cart: {
        name: ""
      }
    };
  }
};
</script>
